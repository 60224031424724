import React from 'react';
import styles from './contacts.module.css';

import Layout from '../components/layout';

const IndexPage: React.SFC = () => (
  <Layout>
    <div className={styles.content}>
      <p>По всем вопросам пишите на</p>
      <p>
        <b>support@swapp.su</b>
      </p>
    </div>
  </Layout>
);

export default IndexPage;
